<template>
  <div class="container">
    <div class="section begyeb is-fullwidth is-fullheight has-text-centered">
      <h1 class="title">További szolgáltatások</h1>
      <div class="columns is-multiline">
        <div class="column is-4" v-for="p in post" :key="p.field_add">
            <div class="notification is-primary is-size-4 py-2"  v-html="p.field_add"></div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'additional',
  props: ['post']
}
</script>

<style scoped>

</style>