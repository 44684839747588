<template>
    <div class="card h-100 extraelem">
        <header class="card-header">
        <p class="card-header-title">
            <label class="checkbox">
            <input type="checkbox">
            {{ title }}
            </label>
        </p>
        <div class="card-header-icon" @click="isactive='modal is-active'">
            <span class="icon">
            <i class="fas fa-angle-down"></i>
            </span>
        </div>
        </header>
    </div>
    <div class="modal" v-bind:class="isactive">
        <div class="modal-background" @click="isactive='modal'"></div>
        <div class="modal-content has-background-white p-6" v-html="body">
        </div>
        <button class="modal-close is-large" aria-label="close" @click="isactive='modal'"></button>
    </div>
</template>

<script>
export default {
  name: 'extra',
  props: ['title', 'body'],
  data() {
      return {
          isactive: ''
      }
  },
}
</script>

<style scoped>
.extraelem:hover {
   box-shadow:
  0 0.7px 3.5px rgba(50, 152, 220, 0.102),
  0 1.7px 8.9px rgba(50, 152, 220, 0.146),
  0 3.4px 18.1px rgba(50, 152, 220, 0.184),
  0 6.9px 37.2px rgba(50, 152, 220, 0.228),
  0 19px 102px rgba(50, 152, 220, 0.33);
}
</style>