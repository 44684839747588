<template>
  <div class="container">
    <div class="section is-fullwidth is-fullheight rolam">
      <h1 class="title has-text-centered pb-3" v-html="post[0].title"></h1>
      <div class="content has-text-justified subtitle" v-html="post[0].body">
      </div>     
    </div>
  </div>  
</template>

<script>

export default {
  name: 'selfless',
  props: ['post']
}
</script>

<style scoped>

</style>