<template>
  <div class="container">
    <div class="section balap is-fullwidth is-fullheight">
      <h1 class="title has-text-centered">Amit mindenképp megkap az oldalához</h1>
      <div class="tabs is-tablet-medium is-mobile-small is-centered is-togle is-boxed">
        <ul>
          <li>
            <a v-bind:class="{'is-active': isDrupal}" @click="active('isDrupal')">Drupal</a>
          </li>
          <li>
            <a v-bind:class="{'is-active': isKinezet}" @click="active('isKinezet')">Kinézet</a>
          </li>
          <li>
            <a v-bind:class="{'is-active': isBetanitas}" @click="active('isBetanitas')">Betanítás</a>
          </li>
          <li>
            <a v-bind:class="{'is-active': isGarancia}" @click="active('isGarancia')">Garancia</a>
          </li>
        </ul>
      </div>
      <div class="content subtitle has-text-justified" v-html="text">
      </div>     
    </div>
  </div>
</template>

<script>
export default {
  name: 'bases',
  props: ['post'],
  data() {
    return {
      isDrupal: true,
      isKinezet: false,
      isBetanitas: false,
      isGarancia: false,
      text: ''
    }
  },
  methods: {
    active(a) {
      this.isDrupal = this.isKinezet = this.isBetanitas = this.isGarancia = false;
      switch (a) {
        case 'isDrupal':
          this.text = this.post[0].field_baserow;
          this.isDrupal = true;
          break;
        case 'isKinezet':
          this.text = this.post[1].field_baserow;
          this.isKinezet = true;
          break;  
        case 'isBetanitas':
          this.text = this.post[2].field_baserow;
          this.isBetanitas = true;
          break;
        case 'isGarancia':
          this.text = this.post[3].field_baserow;
          this.isGarancia = true;
          break;
      }
    }
  },
  created() {
    this.text = this.post[0].field_baserow;
  }
}
</script>

<style scoped>

</style>