<template>
  <div class="container">
    <div class="section bextrak">
      <h1 class="title has-text-centered">Választható extrák weboldalhoz</h1>
      <div class="columns is-multiline">
        <div class="column is-4" v-for='p in post' :key='p.title'>
          <extra :title="p.title" :body="p.body"></extra>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import extra from './extra.vue'

export default {
  name: 'extras',
  props: ['post'],
  components: {
    extra
  },
}
</script>

<style scoped>

</style>