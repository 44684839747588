<template>
  <div class="container">
    <div class="section bkerdesek">
      <h1 class="title has-text-centered">Fontos kérdések</h1>
      <div class="columns is-multiline">
        <div class="column is-6" v-for="p in post" :key="p.title">
          <div class="message is-info h-100 kerdes">
            <div class="message-header" v-html="p.title"></div>
            <div class="message-body">
              <p v-html="p.field_answer" class="has-text-justified pb-3"></p>
              <textarea class="textarea pt-3 h-100"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'questions',
  props: ['post']
}
</script>

<style scoped>
.kerdes:hover {
  box-shadow:
    0 0.4px 1.2px rgba(1, 105, 7, 0.137),
    0 1px 3px rgba(1, 105, 7, 0.133),
    0 2.1px 6px rgba(1, 105, 7, 0.167),
    0 4.4px 12.4px rgba(1, 105, 7, 0.207),
    0 12px 34px rgba(1, 105, 7, 0.3);
}
</style>