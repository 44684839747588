<template> 
  <nav class="navbar is-fixed-top is-info" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a href="https://revil.hu">
          <figure>
            <img src="./assets/logo.png" alt="Revil Webcraft" title="Revil Webcraft" class="image is-64x64">
          </figure>
        </a>
        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="mainmenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div id="mainmenu" class="navbar-menu">
          <div class="navbar-start"></div>
          <div class="navbar-end">
              <a href="#kezdo" class="navbar-item"><span class="icon is-medium"><i class="fas fa-home"></i></span></a>
              <a href="#rolam" class="navbar-item">Rólam</a>
              <a href="#kerdesek" class="navbar-item">Fontos kérdések</a>
              <a href="#alap" class="navbar-item">Biztosan megkapja</a>
              <a href="#extrak" class="navbar-item">További lehetőségek</a>
              <a href="#egyeb" class="navbar-item">Egyéb szolgáltatások</a>
              <a href="#referenciak" class="navbar-item">Referenciák</a>
              <a href="#kapcsolat" class="navbar-item">Kapcsolat</a>
          </div>
          
      </div>
  </nav>
  <nav class="navbar is-fixed-bottom">
    <div class="navbar-menu is-flex is-justify-content-center is-align-items-center">
      <button @click='SendIt()' class="button is-danger ajanlat zoom">Árajánlat kérése</button>
    </div>
  </nav>
  <a name="kezdo" />
  <slider :post='slider'></slider>
  <div class="brolam">
    <a name="rolam" />
    <selfless :post="selfless"></selfless>
  </div>
  <div class="firstplace"></div>
  <div class="bkerdesek">
    <a name="kerdesek" />
    <questions :post="questions"></questions>
  </div>
  <div class="secondplace"></div>
  <div class="balap">
    <a name="alap" />
    <bases :post='bases'></bases>
  </div>
  <div class="thirdplace"></div>
  <div class="bextrak">
    <a name="extrak" />
    <extras :post='extras'></extras>
  </div>
  <div class="fourthplace"></div>
  <div class="begyeb">
    <a name="egyeb" />
    <adds :post='adds'></adds>
  </div>
  <div class="fifthplace"></div>
  <div class="breferenciak">
    <a name="referenciak" />
    <refs :post='refs'></refs>
  </div>
  <div class="container">
    <div class="section box is-fullwidth has-text-centered is-fullheight">
      <h1 class="title">Fejlesztés során használt eszközök</h1>
      <h2 class="subtitle">Köszönet a fejlesztőknek!</h2>
      <div class="columns is-multiline is-centered is-fullwidth">
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://code.visualstudio.com/" target="_blank">
            <img src="./assets/vscode.png" alt="Visual Studio Code" title="Visual Studio Code" class="image is-128x128 zoom">
          </a>  
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="http://drupal.org" target="_blank">
            <img src="./assets/drupal.png" alt="Drupal" title="Drupal" class="image is-128x128 zoom">
          </a>
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="http://bulma.io" target="_blank">
            <img src="./assets/bulma.png" alt="Bulma" title="Bulma" class="image is-128x128 zoom">
          </a>
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="http://vuejs.org" target="_blank">
            <img src="./assets/vuejs.png" alt="Vue" title="Vue" class="image is-128x128 zoom">
          </a>
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">
            <img src="./assets/js.png" alt="JavaScript" title="JavaScript" class="image is-128x128 zoom">
          </a>
        </div>
      </div>
    </div>
    <div class="section box is-fullwidth has-text-centered is-fullheight">
      <h1 class="title has-text-centered">Az oldalak teszteléséhez használt böngészők</h1>
      <div class="columns is-multiline is-centered">
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://www.mozilla.org/hu/firefox/new/" target="_blank">
            <img src="./assets/firefox.png" alt="FireFox" title="FireFox" class="image is-128x128 zoom">
          </a>  
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://brave.com/" target="_blank">
            <img src="./assets/brave.png" alt="Brave" title="Brave" class="image is-128x128 zoom">
          </a>  
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://www.apple.com/safari/" target="_blank">
            <img src="./assets/safari.png" alt="Safari" title="Safari" class="image is-128x128 zoom">
          </a>  
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://www.google.com/intl/hu_hu/chrome/" target="_blank">
            <img src="./assets/chrome.png" alt="Google Chrome" title="Google Chrome" class="image is-128x128 zoom">
          </a>  
        </div>
        <div class="column is-3 is-flex is-justify-content-center m-3 p-6">
          <a href="https://www.microsoft.com/hu-hu/edge" target="_blank">
            <img src="./assets/edge.png" alt="Edge" title="Edge" class="image is-128x128 zoom">
          </a>  
        </div>
      </div>
    </div>
  </div>
  <div class="sixthplace mt-2"></div>
  <div class="has-background-grey">
    <div class="container">
      <a name="kapcsolat" />
      <footer class="footer has-background-grey">
        <h1 class="title has-text-white">Kapcsolat</h1>
        <div class="columns">
          <div class="column is-2"></div>
          <div class="column is-4">
            <div class="has-text-centered p-3">
              <a href="https://www.facebook.com/zadori.nandor" target="_blank" class="has-text-white no-underline subtitle">
                <span class="icon pr-3"><i class="fas fa-signature"></i></span>Zádori Nándor
              </a>
            </div>
            <div class=" has-text-centered p-6">
              <a href="mailto:webcraft@revil.hu" class="has-text-white no-underline subtitle"><span class="icon pr-2"><i class="fas fa-phone-square-alt"></i></span>webcraft@revil.hu</a>
            </div>
          </div>
          <div class="column is-4">
            <div class="subtitle p-3 has-text-white has-text-centered">
              <span class="icon pr-2"><i class="fas fa-phone-square-alt"></i></span> +36 70 379 1864
            </div>
            <div class="is-flex is-justify-content-center">
              <a href="http://signal.org/hu" target="_blank">
                <img src="./assets/signal.png" alt="Signal" title="Signal" class="image is-64x64">
              </a>
            </div>
          </div>
          <div class="column is-2"></div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import selfless from './components/selfless.vue'
import slider from './components/slider.vue'
import questions from './components/questions.vue'
import bases from './components/bases.vue'
import extras from './components/extras.vue'
import adds from './components/adds.vue'
import refs from './components/refs.vue'

export default {
  name: 'App',
  components: {
    selfless,
    slider,
    questions,
    bases,
    extras,
    adds,
    refs,
  },  
  data() {
    return {
      selfless: '',
      slider: '',
      questions: '',
      bases: '',
      extras: '',
      adds: '',
      refs: '',
      contact: ''
    }
  },
  methods: {
    async loader() {
      await fetch('http://revil.hu/admin/api/oldal/Tisztelt%20L%C3%A1togat%C3%B3%21')
        .then(response => response.json())
        .then(data => (this.selfless = data));
      await fetch('http://revil.hu/admin/api/slider')
        .then(response => response.json())
        .then(data => (this.slider = data));
      await fetch('http://revil.hu/admin/api/question')
        .then(response => response.json())
        .then(data => (this.questions = data));
      await fetch('http://revil.hu/admin/api/bases')
        .then(response => response.json())
        .then(data => (this.bases = data));
      await fetch('http://revil.hu/admin/api/extrak')
        .then(response => response.json())
        .then(data => (this.extras = data));
      await fetch('http://revil.hu/admin/api/adds')
        .then(response => response.json())
        .then(data => (this.adds = data));
      await fetch('http://revil.hu/admin/api/referenciak')
        .then(response => response.json())
        .then(data => (this.refs = data));
    },
    SendIt() {
      const data = { field_email: 'valaki@valami.hu' };
      fetch('http://revil.hu/mysql.php', {
        method: 'post',
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: JSON.stringify(data),
      })
      .then(function (data) {
        console.log('Küldés sikeres', data);

      })
      .catch(function (error) {
        console.log('Kérés elutasítva', error);

      })

    }
  },
  created() {
    this.loader()
  }
}
</script>

<style>
html {
    scrollbar-width: none;
}
.is-fullheight {
  min-height: 100vh;
}
.h-100 {
  height: 100%;
}
.zoom {
  transition: transform .2s;
}
.zoom:hover {
  zoom: 1.4;
  -moz-transform: scale(1.4);
}
.no-underline {
  text-decoration: none;
}
.ajanlat:hover {
  box-shadow:
  0 2.5px 13.8px rgba(255, 0, 0, 0.045),
  0 6.1px 33.3px rgba(255, 0, 0, 0.065),
  0 11.4px 62.6px rgba(255, 0, 0, 0.08),
  0 20.3px 111.7px rgba(255, 0, 0, 0.095),
  0 38px 208.9px rgba(255, 0, 0, 0.115),
  0 91px 500px rgba(255, 0, 0, 0.16);
  font-weight: bold;
}
.brolam {
  background-color: #f5e999;
}
.bkerdesek {
  background-color: #c0e27a;
}
.balap {
  background-color: #ebde6d;
}
.bextrak {
  background-color: #99f899;
}
.begyeb {
  background-color: #b4b6af;
}
.breferenciak {
  background-color: #71f6ff;
}
.firstplace {
  width: 100%;
  height: 10vh;
  background: linear-gradient(to right bottom, #f5e999 50%, #c0e27a 50%);
}
.secondplace {
  width: 100%;
  height: 10vh;
  background: linear-gradient(to left bottom, #c0e27a 50%, #ebde6d 50%);
}
.thirdplace {
  width: 100%;
  height: 10vh;
  background: linear-gradient(to right bottom, #ebde6d 50%, #99f899 50%);
}
.fourthplace {
  width: 100%;
  height: 10vh;
  background: linear-gradient(to left bottom, #99f899 50%, #b4b6af 50%);
}
.fifthplace {
  width: 100%;
  height: 10vh;
  background: linear-gradient(to right bottom, #b4b6af 50%, #71f6ff 50%);
}
.sixthplace {
  width: 100%;
  height: 10vh;
  background: linear-gradient(to left bottom, #fff 50%, #7a7a7a 50%);
}
</style>
