<template>
  <div class="container">
    <div class="section breferenciak is-fullwidth has-text-centered is-fullheight">
      <h1 class="title">Referenciák</h1>
      <div class="columns is-multiline is-vcentered">
        <div class="column is-6" v-for="p in post" :key="p.field_referencialink">
          <ref :pic="p.field_referenciakep" :link="p.field_referencialink"></ref>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import ref from './ref.vue'

export default {
  name: 'refs',
  props: ['post'],
  components: {
    ref,
  }
}
</script>

<style scoped>

</style>