<template>
    <div class="box referencia">
        <div class="is-fullwidth has-text-centered">
            <a v-bind:href='link' target="_blank">
                <img v-bind:src='pic'>
            </a>
        </div>
        <div class="is-fullwidth has-text-centered">
            <a v-bind:href='link' class="has-text-link no-underline" target="_blank">{{ link }}</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ref',
  props: ['pic', 'link'],
}
</script>

<style scoped>
.referencia:hover {
    box-shadow:
    0 0.4px 1.2px rgba(50, 152, 220, 0.093),
    0 1px 3px rgba(50, 152, 220, 0.133),
    0 2.1px 6px rgba(50, 152, 220, 0.167),
    0 4.4px 12.4px rgba(50, 152, 220, 0.207),
    0 12px 34px rgba(50, 152, 220, 0.3);
}
</style>